





























import { DashmixBreadcrumbsProps, TableHead, TableRowElement } from '@movecloser/ui-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Inject } from '../../../backoffice'

import {
  HeaderInterface
} from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '../../shared/components/ModelListHeader/ModelListHeader'
import { Query } from '../../shared/contracts/query'

import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import { ISettingsRepository, SettingRepositoryType } from '../contracts'
import {
  ISettingsPermissions,
  SettingsPermissions
} from '../config/permissions'
import { SettingTableRow } from '../components/SettingTabelRow.vue'
import { settingsTableHead } from '../maps/settings'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'SettingList',
  components: {
    InteractiveTable,
    ModelListHeader
  }
})
export class SettingList extends Mixins<ISettingsPermissions>(SettingsPermissions) {
  @Inject(SettingRepositoryType)
  private settingsRepository!: ISettingsRepository

  public isLoading = false
  public itemsTotal: number = 0
  public rowComponent = SettingTableRow
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = settingsTableHead
  public totalPages: number = 0

  public header: HeaderInterface = {
    title: this.$t('settings.all.title').toString()
  }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.all.title')}`,
          target: { name: 'settings.config.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  protected get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  protected loadList (query?: Query): void {
    this.isLoading = true
    this.settingsRepository.loadCollection(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: model.type,
          selectable: true,
          selected: false,
          data: model
        }
      })

      if (collection.meta.total && collection.meta.per_page) {
        this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
        this.itemsTotal = collection.meta.total
      } else {
        this.itemsTotal = collection.length
      }
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }
}

export default SettingList
