// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { Data } from '@vue/composition-api'
import { VueConstructor } from 'vue'

import { HasErrors, HasSize } from '../../composables'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 */
export interface CalculatedPosition {
  width: string
  top: string
  left: string
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 */
export interface SelectItem {
  label: string | SelectLabel
  value: unknown
}

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl> (original)
 */
export enum xSelectStyle {
  Default = ''
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 */
export interface SelectProps extends HasSize, HasErrors, Data {
  autocomplete: string
  clearable: boolean
  filterBy: () => unknown
  isDisabled: boolean
  loading: boolean
  model: unknown | undefined
  multiple: boolean
  options: SelectItem[]
  placeholder: string
  searchable: boolean
  taggable: boolean
  withDynamicPosition: boolean
}

export interface SelectLabel {
  props: AnyObject
  constructor: VueConstructor
}
