






import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'

import {
  defaultComponentConfig,
  SIGNUP_FORM_COMPONENT_KEY,
  signupComponentVariants
} from './Signup.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<SignupForm>({
  name: 'SignupForm',
  created (): void {
    this.config = this.getComponentConfig(SIGNUP_FORM_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class SignupForm extends Mixins(StructureConfigurable) {
  /**
   * Determines the component's variant that is chosen.
   */
  public get component (): VueConstructor {
    const variant = this.getConfigProperty<string>('variant')
    return signupComponentVariants[variant]
  }

  /**
   * Determines the consents of form.
   */
  public get consentsConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('consents')
  }

  public get isAlertUnderForm (): AnyObject {
    return this.getConfigProperty<AnyObject>('isAlertUnderForm')
  }

  public onSuccess (): void {
    this.$emit('onSuccess')
  }

  public get socialsConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('socials')
  }
}

export default SignupForm
