

























import { defineComponent, SetupContext, toRef } from '@vue/composition-api'

import { useErrors, useSyncModel } from '../../composables'
import { PreEditableProps } from './PreEditable.contracts'
import { customPreEditableProps } from './PreEditable.hooks'

/**
 *
 * Component capable to render `<pre />` component with all passed-in args, <br />
 * with possibility to handle invalid state of input or show form errors in a custom way.
 *
 * @displayName PreEditable
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl> (original)
 */
export const PreEditable = defineComponent({
  name: 'CustomPreEditable',
  props: customPreEditableProps,

  setup (props: PreEditableProps, ctx: SetupContext) {
    const { clearErrors, hasErrors } = useErrors(toRef(props, 'errors'), ctx.emit)

    const value = useSyncModel(toRef(props, 'model'), ctx.emit, clearErrors)

    return { value, hasErrors }
  },
  methods: {
    update: function (event: Event) {
      if (event && 'target' in event && event.target) {
        this.errors.pop()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const val = JSON.parse(event.target.innerText)
          this.value = val
        } catch (e) {
          this.errors.push('JSON wrong format')
        }
      }
    },
    paste: function (event: ClipboardEvent) {
      event.preventDefault()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const text = event.clipboardData.getData('text/plain')
      window.document.execCommand('insertText', false, text)
    }
  }
})
export default PreEditable
