import { Injectable, IResponse, mapModel, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../../support'

import { IGraphQL } from '../../../contexts'

import {
  IAuthControl,
  LoginFormPayload,
  RequestResetLinkPayload,
  RequestResetPasswordPayload,
  SignupFormPayload
} from '../contracts'
import { Token } from '../models/token'
import { tokenAdapterMap } from '../models/token.adapter'
import { TokenModel } from '../contracts/models'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Injectable()
export class AuthControl implements IAuthControl {
  protected graphQlConnector: IGraphQL

  constructor (graphQlConnector: IGraphQL) {
    this.graphQlConnector = graphQlConnector
  }

  /**
   * @inheritDoc
   */
  public async checkToken (token: string): Promise<void> {
    // TODO: Remove if not necessary
    return Promise.resolve()
  }

  /**
   * @inheritDoc
   */
  public async login (payload: LoginFormPayload): Promise<TokenModel> {
    const response: IResponse = await this.graphQlConnector.call('loginMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
    const token = response.data.generateCustomerToken.token
    if (!token) {
      throw new ResourceActionFailed(
        'Missing token',
        500
      )
    }

    return Token.hydrate<TokenModel>(
      mapModel<TokenModel>({
        accessToken: token,
        expiresAt: null
      }, tokenAdapterMap, false)
    )
  }

  public async logout (): Promise<void> {
    const response: IResponse = await this.graphQlConnector.call('logoutMutation', {})

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    const result = response.data.revokeCustomerToken.result
    if (!result) {
      throw new ResourceActionFailed(
        'Logout failed',
        500
      )
    }
  }

  /**
   * @inheritDoc
   */
  public async requestResetLink (payload: RequestResetLinkPayload): Promise<void> {
    const response: IResponse = await this.graphQlConnector.call('requestPasswordMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
  }

  /**
   * @inheritDoc
   */
  public async resetPassword (payload: RequestResetPasswordPayload): Promise<void> {
    const response: IResponse = await this.graphQlConnector.call('requestResetPasswordMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }
  }

  /**
   * @inheritDoc
   */
  public async signup (payload: SignupFormPayload): Promise<TokenModel> {
    const response: IResponse = await this.graphQlConnector.call('createUserMutation', payload)

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response.status),
        response.data
      )
    }

    return await this.login({ email: payload.email, password: payload.password })
  }

  /**
   * @inheritDoc
   */
  // public async socialLogin (social: string): Promise<TokenModel> {
  //   logger('AUTH_CONTROL -> SOCIAL_LOGIN', 'debug')
  //   // todo call to api
  //   return mockPromise<UserPayload>(() => {
  //     logger(`Logged in with ${social}. Logging with response`, 'debug')
  //
  //     return {
  //       firstName: faker.name.firstName(),
  //       lastName: faker.name.lastName(),
  //       socialId: faker.datatype.uuid(),
  //       email: faker.internet.email()
  //     }
  //   }, () => {
  //     return new ResourceActionFailed(
  //       'Rejected from API',
  //       resolveFromStatus(401),
  //       {}
  //     )
  //   }).then(async (response) => {
  //     return await this.loginWith(social, response)
  //   }).catch(() => {
  //     return new ResourceActionFailed(
  //       `Rejected from API while registering with social: ${social}`,
  //       resolveFromStatus(401),
  //       {}
  //     )
  //   })
  // }

  // protected loginWith (social: string, payload: UserPayload): Promise<UserSession> {
  //   logger('AUTH_CONTROL -> LOGIN_WITH', 'debug')
  //
  //   // todo call to api
  //   return mockPromise<UserSession>(() => {
  //     logger(`Logged/Registered with ${social} with payload: ${payload}`, 'debug')
  //
  //     return {
  //       token: faker.datatype.uuid(),
  //       user: {
  //         firstName: faker.name.firstName(),
  //         lastName: faker.name.lastName()
  //       }
  //     }
  //   }, () => {
  //     return new ResourceActionFailed(
  //       `Rejected from API while registering with ${social}`,
  //       resolveFromStatus(500),
  //       {}
  //     )
  //   })
  // }
}
