// Copyright © 2022 Move Closer

import { ImageData, Module, ModuleContent, Related } from '../../contexts'

import { AvailableModuleDriver } from '../allowed'
import { HeadingData } from '../partials/HeadingForm'
import { ResolvedLink, UnresolvedLink } from '../partials'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type AddonType = string

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type AddonData = { type: AddonType; content: ModuleContent }

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type HeroModule = Module<HeroModuleData, AvailableModuleDriver.Hero>

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface Slide {
  /**
   * Addon.
   */
  addon?: AddonData

  /**
   * Background image.
   */
  background: ImageData | null

  /**
   * Color of all text content in Hero.
   */
  contentColor?: string

  /**
   * Description.
   */
  description?: string

  /**
   * Heading.
   */
  heading: HeadingData

  /**
   * Link.
   */
  link?: ResolvedLink

  /**
   * Subheading.
   */
  subHeading?: string

  isLinkOnFullImage?: boolean
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface HeroModuleData {
  /**
   * Hero array of slider to render.
   */
  slides: Slide[]
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type HeroModuleContent = HeroModuleData & ModuleContent

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface HeroModuleContentInput extends Omit<HeroModuleContent, 'slides'> {
  slides: SlidesInput[]
}

export interface HeroOptions {
  linkDecorator: string
  linkTheme: string
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export type PossibleAddon = string

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const PossibleAddons: Record<PossibleAddon, string> = {
  Creations: 'creations',
  Image: 'image'
}

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export interface SlidesInput extends Omit<Slide, 'addon' | 'background' | 'link'> {
  addon: AddonData | null
  background: Related | null
  link: UnresolvedLink | null
  id: string
  isLinkOnFullImage: boolean
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum HeroEditMode {
  Content = 'content',
  Order = 'order'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const heroEditModeIcons: Record<HeroEditMode, string> = {
  [HeroEditMode.Content]: 'eye',
  [HeroEditMode.Order]: 'edit-solid'
}
