













import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import { ISettingsPermissions, SettingsPermissions } from '../config/permissions'
import { SettingData } from '../contracts'
import { IForm } from '../contracts/form'
import SettingsForm from './SettingsForm.vue'

@Component({
  name: 'SettingTableRow',
  components: { Fragment }
})
export class SettingTableRow extends Mixins<ISettingsPermissions>(SettingsPermissions) {
  @Prop({ type: Object, required: true })
  protected data!: SettingData

  public Icons = DashmixIconName

  public readonly settingsWithForm = ['departments', 'general', 'banners']

  public get canEditSetting () {
    const forms: IForm[] = this.$root.$options.configuration?.byFile('settings') || []
    const form = forms.filter(item => {
      return item.name === this.data.type
    })
    if (form.length > 0) {
      return true
    }
    return this.settingsWithForm.includes(this.data.type) && this.canEditSettings
  }
}

export default SettingTableRow
