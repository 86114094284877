import { Injectable } from '@movecloser/front-core'

import { IToastsService, ToastType } from './toasts.contracts'
import { IToastOptions } from '../../../../backoffice/shared/services/toaster'
import { Notyf } from 'notyf'

/**
 *
 * **Toast Service**
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Injectable()
export class ToastsService implements IToastsService {
  private readonly config: IToastOptions
  private readonly notify: Notyf

  constructor (config: IToastOptions) {
    this.config = config
    this.notify = new Notyf({
      ...{
        duration: 1000,
        position: {
          x: 'right',
          y: 'top'
        },
        types: [
          {
            type: 'success',
            background: 'sucess',
            icon: {
              className: 'material-icons',
              tagName: 'i',
              text: 'warning'
            }
          },
          {
            type: 'warning',
            background: 'warning',
            icon: {
              className: 'material-icons',
              tagName: 'i',
              text: 'warning'
            }
          },
          {
            type: 'info',
            background: 'info',
            icon: {
              className: 'material-icons',
              tagName: 'i',
              text: 'warning'
            }
          },
          {
            type: 'error',
            background: 'error',
            duration: 2000,
            dismissible: true
          }
        ]
      },
      ...config
    })
  }

  /**
   * Display toast with specified type.
   */
  public show (content: string, type = ToastType.Info, title = '', options?: IToastOptions): void {
    this.notify.open({
      type: type,
      message: (title) ? `${title}: ${content}` : content,
      ...options
    })
  }

  /**
   * Compose options by merging given with default.
   */
  protected composeOptions (options?: IToastOptions): IToastOptions {
    if (!options) {
      return this.config
    }

    return { ...this.config, ...options }
  }
}
