










import { Vue } from 'vue-property-decorator'
import VueLazyload from 'vue-lazyload'
import { computed, defineComponent, SetupContext } from '@vue/composition-api'

import { FALLBACK_LOADER } from './Image.config'
import { ImageProps } from './Image.contracts'
import { imageProps, useImage } from './Image.hooks'

Vue.use(VueLazyload, {
  adapter: {
    loaded ({ el }: { el: HTMLElement }) {
      const srcset = el.getAttribute('data-srcset')
      if (srcset) {
        el.setAttribute('srcset', srcset)
      }
    }
  },
  attempt: 1,
  preLoad: 2.5
})

/**
 * Component capable to render Image element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Image = defineComponent({
  name: 'CustomImage',
  props: imageProps,
  emits: ['error', 'load'],

  setup (props: ImageProps, ctx: SetupContext) {
    /**
     * Source for the loading image (until the target image loads).
     */
    const loadingImage = computed<string>(() => FALLBACK_LOADER)

    return {
      isClient: typeof window !== 'undefined',
      loadingImage,
      ...useImage(props, ctx.emit, undefined, undefined, ctx)
    }
  }
})
export default Image
