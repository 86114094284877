var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VSelect',_vm._g(_vm._b({class:_vm.sizeClass,attrs:{"placeholder":_vm.loading?_vm.$t('dsl.select.loading'):_vm.$props.placeholder,"appendToBody":_vm.withDynamicPosition,"disabled":_vm.isDisabled,"calculatePosition":_vm.withPopper},scopedSlots:_vm._u([{key:"spinner",fn:function(ref){
var loading = ref.loading;
return [(loading)?_c('LoadingIcon',{staticClass:"--loading"}):_vm._e()]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('ArrowIcon',{staticClass:"--arrow-icon"})],1)]}},{key:"selected-option",fn:function(ref){
var label = ref.label;
return [(_vm.isSimpleLabel)?[_vm._v(" "+_vm._s(label)+" ")]:[_c(label.constructor,_vm._b({tag:"component"},'component',label.props,false))]]}},{key:"option",fn:function(ref){
var icon = ref.icon;
var label = ref.label;
return [(_vm.isSimpleLabel)?[_vm._v(" "+_vm._s(label)+" ")]:[_c(label.constructor,_vm._b({tag:"component"},'component',label.props,false))]]}},{key:"no-options",fn:function(){return [_vm._t("no-options",function(){return [_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$t('dsl.select.empty')))])]})]},proxy:true}],null,true),model:{value:(_vm._model),callback:function ($$v) {_vm._model=$$v},expression:"_model"}},'VSelect',_vm.$props,false),_vm.$listeners)),(_vm.hasErrors)?_c('div',{staticClass:"Input__errors"},_vm._l((_vm.errors),function(error,index){return _c('span',{key:index,staticClass:"--t-danger"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }