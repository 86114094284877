/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movcloser.pl>
 */
export function defaultProvider<R> (toReturn: R): () => R {
  return () => toReturn
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movcloser.pl>
 */
export const COMPONENTS_STRUCTURE_CONFIG = 'components'

export const COMPONENTS_STRUCTURE_SUFFIX = 'structure'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const IS_MOBILE_PROVIDER_KEY = 'isMobile'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movcloser.pl>
 */
export const IS_SMART_MODULES_MODE_KEY = 'isSmartModuleMode'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const PARENT_CONTENT_DATA_KEY = '$parentData'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movcloser.pl>
 */
export const PRIVACY_CONFIG = 'privacy'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const QUANTITY_PICKER_CONFIG_PROVIDER_KEY = 'quantityPickerConfig'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
export const REFRESH_MDE_KEY = 'ui:markdown:refresh'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movcloser.pl>
 */
export const UI_CONTAINER_MOUNTED_PROVIDER_KEY = 'ui:container.mounted'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const UI_MODAL_OPENED_EVENT_KEY = 'ui:modal.open'
