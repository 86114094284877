// Copyright © 2022 Move Closer

import uiCore from '@movecloser/ui-core/lib/libraries/dashmix/locales/pl.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { merge } from 'lodash'

// import { localesPL } from '@core'
import { backofficeAppEN } from '@/assets/langs/front/en'

import atomsPL from '@/assets/langs/pl/atoms.json'
import modulesPL from '@/assets/langs/pl/modules.json'
import commonPl from '@/assets/langs/pl/common.json'

import articlesPL from '@backoffice/articles/locales.json'
import contentPl from '@backoffice/content/config/content.json'
import mediaPL from '@backoffice/media/config/media.json'
import productsPL from '@backoffice/products/locales.json'
import settingsPL from '@backoffice/settings/config/settings.json'
import setsPL from '@backoffice/sets/config/sets.json'
import staticPL from '@backoffice/static/locales.json'
import usersPl from '@backoffice/users/config/users.json'

import atomsEN from '@/assets/langs/en/atoms.json'
import modulesEN from '@/assets/langs/en/modules.json'
import commonEN from '@/assets/langs/en/common.json'

import articlesEN from '@backoffice/articles/locales-en.json'
import contentEN from '@backoffice/content/config/content-en.json'
import mediaEN from '@backoffice/media/config/media-en.json'
import productsEN from '@backoffice/products/locales-en.json'
import settingsEN from '@backoffice/settings/config/settings-en.json'
import setsEN from '@backoffice/sets/config/sets-en.json'
import staticEN from '@backoffice/static/locales-en.json'
import usersEN from '@backoffice/users/config/users-en.json'

Vue.use(VueI18n)

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  silentTranslationWarn: true,
  messages: {
    pl: {
      ...merge(
        uiCore,
        backofficeAppEN,
        atomsPL,
        commonPl,
        articlesPL,
        contentPl,
        mediaPL,
        productsPL,
        setsPL,
        settingsPL,
        staticPL,
        usersPl,
        modulesPL
      )
    },
    en: {
      ...merge(
        uiCore,
        backofficeAppEN,
        atomsEN,
        commonEN,
        articlesEN,
        contentEN,
        mediaEN,
        productsEN,
        setsEN,
        settingsEN,
        staticEN,
        usersEN,
        modulesEN
      )
    }
  }
})
