























































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Form } from '../../../shared/molecules/Form'

import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'
import { ConnectorErrors, Inject, logger } from '../../../../support'

import { IAuthControl, AuthControlServiceType } from '../../contracts'
import { TokenModel } from '../../contracts/models'
import { UserModel } from '../../shared'

import { LoginFormFormData } from './LoginForm.contracts'
import { loginValidationsMap } from './LoginForm.helpers'
import { defaultComponentConfig, LOGIN_FORM_COMPONENT_KEY } from './LoginForm.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<LoginForm>({
  name: 'LoginForm',
  components: { Form },
  created (): void {
    this.config = this.getComponentConfig(LOGIN_FORM_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class LoginForm extends Mixins(FormErrorsMixin, StructureConfigurable) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Prop({
    required: false, type: Array, default: () => []
  })
  public version!: ('secondary' | 'no-socials' | 'no-labels')[]

  public error: string | null = null

  public socialPending: boolean = false

  /**
   * Validators map.
   */
  public validatorsMap = loginValidationsMap

  /**
   * Payload of the form.
   */
  public payload: LoginFormFormData = {
    email: '',
    password: ''
  }

  public get isAlertUnderForm (): AnyObject {
    return this.getConfigProperty<AnyObject>('isAlertUnderForm')
  }

  /**
   * Sets props for submit button.
   */
  public get submitProps () {
    return {
      theme: this.version.includes('secondary') ? 'secondary' : 'primary',
      isFluid: this.version.includes('secondary')
    }
  }

  public get isSocialLoginAvailable (): boolean {
    return typeof this.authControl.socialLogin === 'function'
  }

  /**
   * Determines whether inputs should have floating labels.
   */
  public get hasAnimatedLabel (): boolean {
    return this.getConfigProperty<AnyObject>('input').animatedLabel
  }

  /**
   * Determines whether inputs should have floating labels.
   */
  public get hasFormLabel (): boolean {
    return this.getConfigProperty<AnyObject>('form').hasLabel
  }

  /**
   * Determines whether lead text should be displayed.
   */
  public get hasLeadText (): boolean {
    return this.getConfigProperty<boolean>('hasLeadText')
  }

  /**
   * Determines whether form should have a reversed order.
   */
  public get isReversed (): boolean {
    return this.getConfigProperty<AnyObject>('form').reverseOrder
  }

  /**
   * Determines whether password type is simple for current project.
   */
  public get isPasswordTypeSimple (): boolean {
    return this.getConfigProperty<AnyObject>('password').simple
  }

  public get socialsConfig (): AnyObject {
    return this.getConfigProperty<AnyObject>('socials')
  }

  /**
   * Login submit
   */
  public login (): Promise<TokenModel> {
    this.cleanError()

    return this.authControl.login(this.payload).then((response) => {
      return response
    })
  }

  /**
   * Callback to sign with facebook.
   */
  public async socialLogin (social: string): Promise<void> {
    if (typeof this.authControl.socialLogin !== 'function') {
      return
    }
    // TODO after launch
    try {
      this.cleanError()
      this.socialPending = true

      const userSession = await this.authControl.socialLogin(social)

      this.authService.setToken(userSession)
      this.authService.setToken(userSession)
    } catch (error) {
      this.setError(error as Error)
    } finally {
      this.socialPending = false
    }
  }

  /**
   * Handles @success event of `Form`
   */
  public onFail (error: Error): void {
    this.setError(error)
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (tokenModel: TokenModel): void {
    this.authService.setToken(tokenModel.toAuthToken())

    this.$emit('onSuccess')
  }

  /**
   * Cleans the error
   */
  protected cleanError (): void {
    this.error = null
  }

  protected setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.error = error.message
  }
}

export default LoginForm
