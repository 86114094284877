// Copyright © 2022 Move Closer

import { ModuleSize } from '@movecloser/page-builder'

import { ModuleBase, ModuleFactory } from '../../contexts'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../abstract'
import { AvailableModuleDriver } from '../allowed'

import { TabsModule, TabsModuleContent } from './Tabs.contracts'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const tabsContentFactory = (): TabsModuleContent => ({ tabs: [] })

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
export const tabsFactory: ModuleFactory<TabsModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 12,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 3,
      minColSpan: 4,
      minRowSpan: 3,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<TabsModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: tabsContentFactory(),
      driver: AvailableModuleDriver.Tabs
    }
  }
}
