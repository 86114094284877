













import { defineComponent, SetupContext } from '@vue/composition-api'
import DatePicker from 'vue2-datepicker/index'
import 'vue2-datepicker/locale/pl'

import { dateTimePickerProps, useDateTimePicker } from './DateTimePicker.hooks'
import { DateTimePickerProps } from './DateTimePicker.contracts'
import CalendarIcon from './CalendarIcon.vue'

/**
 * Compoenent capable to render DateTime picker atom.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const DateTimePicker = defineComponent({
  name: 'DateTimePicker',
  components: { CalendarIcon, DatePicker },
  props: dateTimePickerProps,

  setup (props: DateTimePickerProps, ctx: SetupContext) {
    return useDateTimePicker(props, ctx)
  }
})
export default DateTimePicker
