// Copyright © 2022 Move Closer

import { AppConfig, RouterDriver, StoreDriver } from '@movecloser/front-core'
import { COMPONENTS_STRUCTURE_CONFIG } from '@core'

import { auth } from './auth'
import { builder } from './builder'
import { csrf } from './csrf'
import { http } from './http'
import { iconsRegistry } from '@/shared/icons'
import { middlewares } from './middlewares'
import { modalConfig, modals } from './modals'
import { modules } from './modules'
import { defaultRoutesConfig, navigation } from './navigation'
import { resources } from './resources'
import { services } from './services'
import { toasts } from './toasts'
import { uploader } from './uploader'
import { settings } from './settings'
import { configuration } from '@/config/components'

import { IconsRegistry } from '@/shared/core/src/front/shared/icons'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const config: AppConfig & { iconsRegistry: IconsRegistry } = {
  // This is global config index. Use it to register any properties
  // that should be available inside application.
  // To access any of them use: Configuration
  auth,
  builder,
  [COMPONENTS_STRUCTURE_CONFIG]: configuration,
  csrf,
  defaultRoutesConfig,
  http,
  iconsRegistry,
  middlewares,
  modalConfig,
  modals,
  modules,
  navigation,
  resources,
  router: RouterDriver.VueRouter,
  services,
  settings,
  store: StoreDriver.None,
  toasts,
  uploader
}
