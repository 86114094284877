import { AnyObject } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { SignupGeneral, SignupSimplified } from './variants'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const SIGNUP_FORM_COMPONENT_KEY = 'SignupFormComponent'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export enum SignupComponentVariant {
  General = 'general',
  Simplified = 'simplified'
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const signupComponentVariants: Record<string, VueConstructor> = {
  [SignupComponentVariant.General]: SignupGeneral,
  [SignupComponentVariant.Simplified]: SignupSimplified
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  consents: {
    group: false,
    options: []
  },
  input: {
    animatedLabel: true
  },
  variant: SignupComponentVariant.General,
  isAlertUnderForm: false
}
