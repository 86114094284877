















import { defineComponent, SetupContext } from '@vue/composition-api'
import { Fragment } from 'vue-fragment'

import { StarsRateProps } from './StarsRate.contracts'
import { starsRateProps, useStarsRate } from './StarsRate.hooks'

/**
 * Component capable to render StarsRate element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const StarsRate = defineComponent({
  name: 'StarsRate',
  components: { Fragment },
  props: starsRateProps,

  setup (props: StarsRateProps, ctx: SetupContext) {
    return useStarsRate(props, ctx.emit)
  }
})
export default StarsRate
