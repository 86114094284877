// Copyright © 202w Move Closer

import { BadgeVariant } from '@/shared/core/src/dsl/atoms/Badge'

export const configuration = {
  // e.g.
  // MegaMenuComponent: {
  //   bannerPosition: 'left'
  // },
  structure: {
    ProductCard: {
      buttonType: 'primary',
      badgeVariant: BadgeVariant.Outline,
      badgeTheme: ''
    },
    MegaMenu: {
      hasColumnAdditionalHeaderOnBottom: true,
      headerLinkSize: 'small'
    }
  },
  options: {
    // Warning, property name must be the same as component name
    HeadingModuleForm: {
      decorators: ['default', 'underline']
    },
    ContainerForm: {
      backgroundVariants: ['default', 'small', 'cropped'],
      backgroundColorPalette: {
        primary: '#2B343E',
        white: '#fff',
        black: '#000',
        gray: '#F3F3F4'
      }
    },
    TextModuleForm: {
      allowedColors: { primary: 'primary', gray: 'gray' }
    },
    ButtonModuleForm: {
      decorators: {
        Default: 'default',
        ButtonWithArrow: 'arrow'
      }
    },
    ProductsTeaserSourceForm: {
      modeOptions: ['filters', 'category', 'line']
    }
  }
}
