import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const LOGIN_FORM_COMPONENT_KEY = 'LoginFormComponent'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  hasLeadText: false,
  form: {
    hasLabel: true,
    reverseOrder: false
  },
  input: {
    animatedLabel: true
  },
  password: {
    simple: false
  },
  socials: {
    display: false,
    simple: true
  },
  isAlertUnderForm: false
}
