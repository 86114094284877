// Copyright © 2021 Move Closer

import 'reflect-metadata'
import VTooltip from 'v-tooltip'
import Vue from 'vue'
import VueMeta from 'vue-meta'
import { DashmixDSL } from '@movecloser/ui-core'

import { CustomDSL, FrontCore, registerExtensions as coreExtensions } from '@core'
import { BootedApp, createApp } from '@/bootstrap/app'
import { i18n } from '@/shared/support/i18n'
import { initSentry } from '@backoffice/shared/support/sentry'
import { installFetchRelated } from '@plugin/fetch-related.hook'
import { registerExtensions } from '@backoffice/shared/extentions'
import { buildDSLRegistry } from '@/shared/icons'

import './assets/scss/main.scss'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(CustomDSL, {
  icons: buildDSLRegistry(),
  prefix: 'Ui'
})
Vue.use(DashmixDSL)
Vue.use(FrontCore)

Vue.use(VueMeta)
Vue.use(VTooltip)

installFetchRelated(Vue)
registerExtensions(Vue)

createApp().then((core: BootedApp) => {
  const { configuration, container, router } = core

  if (process.env.VUE_APP_ENV !== 'local') {
    initSentry()
  }

  coreExtensions(Vue)

  new Vue({
    router,
    container,
    configuration,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
