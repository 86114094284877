




















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { SelectItem } from '../../../../dsl/atoms/Select'

import {
  ChangeAddressFormFieldsData,
  ChangeAddressFormFieldsProps
} from './ChangeAddressFormFields.contracts'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ChangeAddressFormFields>({
  name: 'ChangeAddressFormFields',
  created () {
    this.model = { ...this.formData }
  }
})
export class ChangeAddressFormFields extends Vue {
  @Prop({ type: Array, required: true })
  public countries!: ChangeAddressFormFieldsProps['countries']

  @Prop({ type: Object, required: false, default: () => ({}) })
  public errors!: ChangeAddressFormFieldsProps['errors']

  @Prop({ type: Object, required: true })
  protected readonly formData!: ChangeAddressFormFieldsData

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isCompany?: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly isEditable!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly noPerson!: boolean

  public model: ChangeAddressFormFieldsData = {} as ChangeAddressFormFieldsData

  /**
   * Gets error for specified fields.
   */
  public getErrors (key: string): string[] | undefined {
    if (!this.errors || !this.errors[key]) {
      return
    }

    return this.errors[key].map(e => String(this.$t(e)))
  }

  public get countriesOptions (): SelectItem[] {
    return this.countries.map((country) => {
      return {
        label: country.label,
        value: country.code
      }
    })
  }

  @Watch('model', { deep: true })
  protected onModelChange (value: ChangeAddressFormFieldsData): void {
    this.$emit('update:errors')
    this.$emit('update:formData', value)
  }
}

export default ChangeAddressFormFields
