

import { AnyObject } from '@movecloser/front-core'
import { InputType } from '../../../contracts/form'
import { FormInput, FormText, PreEditable } from '../../../../shared/components'
import { defineComponent, SetupContext, toRef } from '@vue/composition-api'
import { useErrors, useSyncModel } from '../../../../../dsl/composables'
import { FieldProps } from './Field.contracts'
import { fieldInputProps } from './Field.hooks'

export const Field = defineComponent({
  name: 'Field',
  components: { FormInput, FormText, PreEditable },
  props: fieldInputProps,
  setup (props: FieldProps, ctx: SetupContext) {
    const { clearErrors, hasErrors } = useErrors(toRef(props, 'errors'), ctx.emit)
    const value = useSyncModel(toRef(props, 'model'), ctx.emit, clearErrors)

    return { hasErrors, value }
  },
  methods: {
    getComponentByType (type: string): string {
      switch (type) {
        case InputType.Checkbox:
          return 'DsCheck'
        case InputType.Json:
          return 'PreEditable'
        case InputType.Number:
          return 'FormInput'
        case InputType.Text:
          return 'FormInput'
        case InputType.H5:
          return 'UiHeading'
        default:
          return 'FormInput'
      }
    },
    getComponentByTypeProps (type: string): AnyObject {
      switch (type) {
        case InputType.Checkbox:
          return {}
        case InputType.Text:
          return {}
        case InputType.Number:
          return { type: 'number' }
        case InputType.H5:
          return { level: 5 }
        default:
          return {}
      }
    },
    getId () {
      if ('id' in this.config) {
        return this.config.id
      }
      return this.name
    },
    getPlaceholder () {
      if ('placeholder' in this.config && this.config.placeholder) {
        return this.$t(this.config.placeholder)
      }
      if (this.form && 'name' in this.config) {
        return this.$t(`settings.form.${this.form}.${this.config.name}.placeholder`)
      }
      return ''
    },
    getLabel () {
      if ('label' in this.config && this.config.label) {
        return this.$t(this.config.label)
      }

      if (this.form && 'name' in this.config) {
        return this.$t(`settings.form.${this.form}.${this.config.name}.label`)
      }
      return ''
    },
    getClass () {
      if ('class' in this.config && this.config.class) {
        return this.$t(this.config.class)
      }
      return 'col-12 mb-4'
    }
  },
  template: `
    <div :class="getClass()">
    <component :is="getComponentByType(config.type)"
               :id="getId()"
               :form="form"
               :name="config.name"
               :props="getComponentByType(config.type)"
               :config="config"
               :model.sync="value"
               :label="getLabel()"
               :placeholder="getPlaceholder()"
               v-bind="{ ...getComponentByTypeProps(config.type) }"
    >
      <span v-html="getLabel()"></span>
    </component>
    </div>
  `
})

export default Field
