










import { Component, Inject as VueInject, Prop, Vue } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'
import { HasId } from '@movecloser/page-builder'
import LazyHydrate from 'vue-lazy-hydration'

import { AllowedImageRatio, isImageFile } from '../../../contexts'
import {
  ContainerBackground,
  ContainerProps,
  CustomContainer
} from '../../../dsl/molecules/Container'
import { ImageProps } from '../../../dsl/atoms/Image'
import {
  defaultProvider,
  Inject,
  IS_MOBILE_PROVIDER_KEY,
  UI_CONTAINER_MOUNTED_PROVIDER_KEY
} from '../../../support'
import { toImageProps } from '../../../front/shared/support'

import { ContainerContent } from '../Container.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<Container>({
  name: 'Container',
  components: { CustomContainer, LazyHydrate },
  mounted (): void {
    this.eventBus.emit(UI_CONTAINER_MOUNTED_PROVIDER_KEY, { id: this.id })
  }
})
export class Container extends Vue {
  @Prop({ type: Object, required: true })
  public readonly data!: ContainerContent & HasId

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  private readonly isMobile!: () => boolean

  public get containerProps (): ContainerProps | null {
    const background: Partial<ContainerBackground> = { ...this.data.background, image: undefined }

    let image: ImageProps | undefined
    if (this.data.background.image && isImageFile(this.data.background.image)) {
      image = toImageProps(
        this.data.background.image,
        this.imageRatio
      )
    }

    background.image = image

    return { ...this.data, background }
  }

  public get id (): HasId['id'] {
    return this.data.id
  }

  protected get imageRatio (): AllowedImageRatio {
    if (this.isMobile()) {
      return AllowedImageRatio.Original
    }

    return AllowedImageRatio.Original
  }

  public get shouldRender (): boolean {
    return this.containerProps !== null
  }
}

export default Container
