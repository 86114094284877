// Copyright © 2021 Move Closer

import { PossibleRelatedType } from '@core'

import { Permission } from '@module/auth/contracts/permissions'
import { NavigationItem } from '@component/Navigation/Navigation.contracts'

export interface DefaultRoute {
  path?: string
  meta: { permissions: Permission[] }
  name: string
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export const defaultRoutesConfig: DefaultRoute[] = [
  // TODO dashboard in this place
  {
    path: '/media/list',
    name: 'media.list',
    meta: { permissions: [Permission.ViewMedia] }
  },
  {
    path: '/users',
    name: 'users.list',
    meta: {
      permissions: [Permission.ViewUsers]
    }
  },
  {
    path: '/users/roles',
    name: 'users.roles.list',
    meta: {
      permissions: [Permission.ViewRoles]
    }
  },
  {
    path: '/authors',
    name: 'users.authors.list',
    meta: {
      permissions: [Permission.ViewAuthors]
    }
  }
]

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const navigation: NavigationItem[] = [
  {
    icon: 'fa fa-fw fa-tachometer-alt',
    label: 'dashboard',
    route: { name: 'root.dashboard' },
    permissions: [
      Permission.ViewContents
    ]
  },
  {
    icon: 'fa fa-camera',
    label: 'media',
    children: [
      {
        label: 'fileDirectory',
        route: { name: 'media.list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'pendingFiles',
        route: { name: 'media.waiting-list' },
        permissions: [
          Permission.ViewMedia
        ]
      },
      {
        label: 'gallery',
        route: { name: 'media.gallery.list' },
        permissions: [
          Permission.ViewMedia
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-project-diagram',
    label: 'pages',
    children: [
      {
        label: 'addNew',
        route: { name: 'content.create', params: { type: 'page' } },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'browseThePages',
        route: { name: 'content.list', params: { type: 'page' } },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'toBeAccepted',
        route: { name: 'content.acceptance.list', params: { type: 'page' } },
        permissions: [
          Permission.AcceptContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-newspaper',
    label: 'blog',
    children: [
      {
        label: 'addEntry',
        route: { name: 'content.create', params: { type: 'article' } },
        permissions: [
          Permission.CreateContents
        ]
      },
      {
        label: 'browseEntries',
        route: { name: 'content.list', params: { type: 'article' } },
        permissions: [
          Permission.ViewContents
        ]
      },
      {
        label: 'toBeAccepted',
        route: { name: 'content.acceptance.list', params: { type: 'article' } },
        permissions: [
          Permission.AcceptContents
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-shopping-cart',
    label: 'products',
    route: { name: 'products.list' },
    permissions: [
      Permission.ViewProducts
    ]
  },
  {
    icon: 'fa fa-2x fa-folder-open',
    label: 'lists',
    children: [
      {
        label: 'articleLists',
        route: { name: 'sets.list', params: { type: PossibleRelatedType.Articles } },
        permissions: [
          // Permission.ViewArticleSet
        ]
      },
      {
        label: 'productsLists',
        route: { name: 'sets.list', params: { type: PossibleRelatedType.Products } },
        permissions: [
          // Permission.ViewProductsSet
        ]
      }
    ]
  },
  {
    icon: 'fa fa-fw fa-users',
    label: 'users',
    children: [
      {
        label: 'usersList',
        route: { name: 'users.list' },
        permissions: [
          Permission.ViewUsers
        ]
      },
      {
        label: 'roleManagement',
        route: { name: 'users.roles.list' },
        permissions: [
          Permission.ViewRoles
        ]
      }
      // {
      //   label: 'Lista autorów',
      //   route: { name: 'users.authors.list' },
      //   permissions: [
      //     // todo autorzy
      //     // Permission.ViewAuthors
      //   ]
      // }
    ]
  },
  {
    icon: 'fa fa-fw fa-cogs',
    label: 'settings',
    children: [
      {
        label: 'settings',
        route: { name: 'settings.config.list' },
        permissions: [
          Permission.ViewSettings
        ]
      },
      {
        label: 'navigation',
        route: { name: 'settings.navigation' },
        permissions: [
          Permission.ViewNavigation
        ]
      },
      {
        label: 'banners',
        route: { name: 'settings.config.edit', params: { type: 'banners' } },
        permissions: [
          Permission.ViewSettings
        ]
      },
      {
        label: 'fragments',
        route: { name: 'content.list', params: { type: 'snippet' } },
        permissions: [
          Permission.ViewLayout
        ]
      },
      {
        label: 'layouts',
        route: { name: 'layouts.layouts.list' },
        permissions: [
          Permission.ViewLayout
        ]
      }
    ]
  }
]
