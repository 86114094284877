























import { defineComponent, SetupContext, toRef } from '@vue/composition-api'

import { useErrors, useSyncModel } from '../../composables'

import { InputProps } from './Input.contracts'
import { customInputProps } from './Input.hooks'

/**
 * Component capable to render Input element.
 *
 * @displayName Input
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Input = defineComponent({
  name: 'CustomInput',
  props: customInputProps,

  setup (props: InputProps, ctx: SetupContext) {
    const { clearErrors, hasErrors } = useErrors(toRef(props, 'errors'), ctx.emit)

    const value = useSyncModel(toRef(props, 'model'), ctx.emit, clearErrors)
    const inputPlaceholder = (props.required && !props.hasAnimatingLabel) ? props.placeholder + '*' : props.placeholder

    return { hasErrors, inputPlaceholder, value }
  }
})
export default Input
