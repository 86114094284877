// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { MetaPropertyProperty } from 'vue-meta'

import { Identifier } from '../related'
import { SiteModel, SitesMap } from './data.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export interface ISiteService {
  getActiveSite (): SiteModel
  getActiveSiteAddress (): string
  getActiveSiteBasePath (): string
  getActiveSiteLocale (): string
  getActiveSiteMeta (): MetaPropertyProperty[]
  getActiveSiteUrls (): Record<string, string>
  getProperty (key: string): AnyObject
  getSites (): SiteModel[]
  getSiteById (id: Identifier): SiteModel | undefined
  setActive (site: SiteModel): void
  setSites (sites: SitesMap): void
  setProperty (key: string, payload: object): void
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SiteServiceType = Symbol.for('ISiteService')
